<template>
  <div id="exhibition_list">
    <!-- loader -->
    <div v-if="photos.length === 0" class="global-loading">
      <div v-html="translate.global.loading_exhibition_data[language] + ' &hellip;'"></div>
    </div>
    <!-- header -->
    <site-header :title="translate.global.exhibit_title[language] + '<br />' + translate.global.exhibit_subtitle[language]" />
    <b-container fluid class="pb-3 bg-white">
      <div class="row">
        <!-- list -->
        <div class="col-lg-8">
          <h2 v-if="currentDate" class="list_title"
            v-html="translate.global.exponate_ausstellung_mit_datum[language] + ': ' +
            currentDateHr"></h2>
          <h2 v-else-if="earliestDate && latestDate" class="list_title"
            v-html="translate.global.exponate_ausstellung_mit_datum[language] + ': ' +
            earliestDateHr + ' - ' + latestDateHr"></h2>
          <ExhibitionList
            v-for="(photoId, i) in photosByDateInExhibition"
            :photoId="photoId"
            :key="'photolist_item_inex_' + i" />
          <h2 v-if="currentDate" class="list_title"
            v-html="translate.global.exponate_bestand_mit_datum[language] + ': '
            + currentDateHr"></h2>
          <ExhibitionList
            v-for="(photo, i) in photosByDateInStore"
            :photoId="photo['o:id']"
            :key="'photolist_item_instore_' + i" />
        </div>
        <!-- side -->
        <div class="col-lg-4 exhibition_side_col">
          <h1 v-if="currentDateHr"
            v-html="translate.metadata_label['Datum (Ausgabe)'][language] + ': ' +
            currentDateHr"></h1>
          <h1 v-else-if="earliestDate && latestDate"
            v-html="translate.metadata_label['Datum (Ausgabe)'][language] + ': ' +
            earliestDateHr + ' - ' + latestDateHr"></h1>
          <hr>
          <dl>
            <dt v-html="translate.metadata_label['thematische Kategorie'][language]"></dt>
            <dd><SubjectcategoryNavigation /></dd>
            <dt v-html="translate.metadata_label['Personen'][language]"></dt>
            <dd><PersonsNavigation /></dd>
            <dt v-html="translate.metadata_label['Orte'][language]"></dt>
            <dd><LocationsNavigation /></dd>
          </dl>
          <hr>
          <GolbalListLinks />
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import header from '@/components/Header.vue'
import ExhibitionList from '@/components/partials/ExhibitionList.vue'
import SubjectcategoryNavigation from '@/components/partials/SubjectcategoryNavigation.vue'
import PersonsNavigation from '@/components/partials/PersonsNavigation.vue'
import LocationsNavigation from '@/components/partials/LocationsNavigation.vue'
import GolbalListLinks from '@/components/partials/GolbalListLinks.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'Date',
  components: {
    'site-header': header,
    ExhibitionList,
    SubjectcategoryNavigation,
    PersonsNavigation,
    LocationsNavigation,
    GolbalListLinks
  },
  data () {
    return {
      currentDate: null,
      currentDateHr: null,
      currentDateTs: null,
      earliestDate: null,
      earliestDateHr: null,
      earliestDateTs: null,
      latestDate: null,
      latestDateHr: null,
      latestDateTs: null,
    }
  },
  watch: {
    '$route' (to) {
      if (to.params.date) {
        this.setCurrentDate(to.params.date)
      }
      if (to.params.earliest) {
        this.setEarliestDate(to.params.earliest)
      }
      if (to.params.latest) {
        this.setLatestDate(to.params.latest)
      }
    }
  },
  methods: {
    setCurrentDate(date) {
      this.currentDate = date.replace(/(<([^>]+)>)/ig,"")
      this.setCurrentDateHr()
      this.setCurrentDateTs()
    },
    setCurrentDateHr() {
      if (this.currentDate && this.currentDate.length > 0) {
        this.currentDateHr = new Date(this.currentDate).toLocaleDateString()
      }
    },
    setCurrentDateTs() {
      if (this.currentDate && this.currentDate.length > 0) {
        this.currentDateTs = Date.parse(this.currentDate)
      }
    },
    setEarliestDate(date) {
      this.earliestDate = date.replace(/(<([^>]+)>)/ig,"")
      this.setEarliestDateHr()
      this.setEarliestDateTs()
    },
    setEarliestDateHr() {
      if (this.earliestDate && this.earliestDate.length > 0) {
        this.earliestDateHr = new Date(this.earliestDate).toLocaleDateString()
      }
    },
    setEarliestDateTs() {
      if (this.earliestDate && this.earliestDate.length > 0) {
        this.earliestDateTs =  Date.parse(this.earliestDate)
      }
    },
    setLatestDate(date) {
      this.latestDate = date.replace(/(<([^>]+)>)/ig,"")
      this.setLatestDateHr()
      this.setLatestDateTs()
    },
    setLatestDateHr() {
      if (this.latestDate && this.latestDate.length > 0) {
        this.latestDateHr = new Date(this.latestDate).toLocaleDateString()
      }
    },
    setLatestDateTs() {
      if (this.latestDate && this.latestDate.length > 0) {
        this.latestDateTs = Date.parse(this.latestDate)
      }
    },
    hasDatePhotoInExhibition(id) {
      let date = this.photos.find(prop =>
        (
          prop['o:id'] === id &&
          this.currentDate !== null &&
          typeof this.currentDate !== 'undefined' &&
          (
            (
              typeof prop['ghwk:date'] !== 'undefined' &&
              typeof prop['ghwk:date'].find(
                dateprop => dateprop['@value'] === this.currentDate) !== 'undefined'
            )
            ||
            (
              typeof prop['ghwk:earliest'] !== 'undefined' &&
              typeof prop['ghwk:earliest'].find(
                dateprop => Date.parse(dateprop['@value']) <= this.currentDateTs) !== 'undefined' &&
              typeof prop['ghwk:latest'] !== 'undefined' &&
              typeof prop['ghwk:latest'].find(
                dateprop => Date.parse(dateprop['@value']) >= this.currentDateTs) !== 'undefined'
            )
          )
        )
        ||
        (
          prop['o:id'] === id &&
          this.earliestDateTs !== null &&
          typeof this.earliestDateTs !== 'undefined' &&
          this.latestDateTs !== null &&
          typeof this.latestDateTs !== 'undefined' &&
          (
            (
              typeof prop['ghwk:earliest'] !== 'undefined' &&
              typeof prop['ghwk:earliest'].find(
                dateprop => Date.parse(dateprop['@value']) >= this.earliestDateTs) !== 'undefined' &&
              typeof prop['ghwk:latest'] !== 'undefined' &&
              typeof prop['ghwk:latest'].find(
                dateprop => Date.parse(dateprop['@value']) <= this.latestDateTs) !== 'undefined'
            )
            ||
            (
              typeof prop['ghwk:date'] !== 'undefined' &&
              typeof prop['ghwk:date'].find(
                dateprop => Date.parse(dateprop['@value']) >= this.earliestDateTs) !== 'undefined' &&
              typeof prop['ghwk:date'].find(
                dateprop => Date.parse(dateprop['@value']) <= this.latestDateTs) !== 'undefined'
            )
          )
        )
      )
      return (typeof date !== 'undefined')? true : false
    },
    hasDatePhotoInStore(photo) {
      if (photo['ghwk:exhibitstatus'][0]['@value'] !== 'im Bestand') {
        return false
      }
      let result = false

      if (this.currentDate !== null &&
        typeof this.currentDate !== 'undefined')
      {

        if (typeof photo['ghwk:date'] !== 'undefined') {
          result = typeof photo['ghwk:date'].find(
            prop => prop['@value'] === this.currentDate) !== 'undefined'

        } else if (typeof photo['ghwk:earliest'] !== 'undefined' &&
          typeof photo['ghwk:latest'] !== 'undefined')
        {
          result = typeof photo['ghwk:earliest'].find(
              prop => Date.parse(prop['@value']) <= this.currentDateTs) !== 'undefined' &&
            typeof photo['ghwk:latest'].find(
              prop => Date.parse(prop['@value']) >= this.currentDateTs) !== 'undefined'
        }

      } else if (this.earliestDateTs !== null &&
        typeof this.earliestDateTs !== 'undefined' &&
        this.latestDateTs !== null &&
        typeof this.latestDateTs !== 'undefined')
      {

        if (typeof photo['ghwk:earliest'] !== 'undefined' &&
          typeof photo['ghwk:latest'] !== 'undefined')
        {
          result = typeof photo['ghwk:earliest'].find(
              dateprop => Date.parse(dateprop['@value']) >= this.earliestDateTs) !== 'undefined' &&
            typeof photo['ghwk:latest'].find(
              dateprop => Date.parse(dateprop['@value']) <= this.latestDateTs) !== 'undefined'

        } else if (typeof photo['ghwk:date'] !== 'undefined') {
          result = typeof photo['ghwk:date'].find(
              dateprop => Date.parse(dateprop['@value']) >= this.earliestDateTs) !== 'undefined' &&
            typeof photo['ghwk:date'].find(
              dateprop => Date.parse(dateprop['@value']) <= this.latestDateTs) !== 'undefined'
        }

      }
      return result
    }
  },
  computed: {
    ...mapGetters({
      language: 'language',
      translate: 'translate/translate',
      photos: 'items/photos',
      photosInExhibition: 'items/photosInExhibition'
    }),
    photosByDateInExhibition: function () {
      let photos = []
      this.photosInExhibition.forEach(photoId => {
        if (this.hasDatePhotoInExhibition(photoId)) {
          photos.push(photoId);
        }
      });
      return photos;
    },
    photosByDateInStore: function () {
      let photos = []
      this.photos.forEach(photo => {
        if (this.hasDatePhotoInStore(photo)) {
          photos.push(photo);
        }
      });
      return photos;
    },
    // getAllDateUrls: function () {
    //   let urls = []
    //   this.photos.forEach(photo => {
    //     if (typeof photo['ghwk:date'] !== 'undefined') {
    //       urls.push('/date/' + photo['ghwk:date'][0]['@value'])
    //     }
    //     if (typeof photo['ghwk:earliest'] !== 'undefined' &&
    //       typeof photo['ghwk:latest'] !== 'undefined') {

    //       urls.push('/daterange/' + photo['ghwk:earliest'][0]['@value'] + '/' + photo['ghwk:latest'][0]['@value'])
    //     }
    //   });
    //   return [...new Set(urls.sort())];
    // }
  },
  created () {
    if (this.$route.params.date) {
      this.setCurrentDate(this.$route.params.date)
    }
    if (this.$route.params.earliest) {
      this.setEarliestDate(this.$route.params.earliest)
    }
    if (this.$route.params.latest) {
      this.setLatestDate(this.$route.params.latest)
    }
  }
}
</script>